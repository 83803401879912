import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { MatTableModule } from '@angular/material/table'
import { PositionsTableTeam } from '@app/models/server'
import { FirstLetterPipe } from '@app/shared'

@Component({
  selector: 'app-positions',
  standalone: true,
  imports: [CommonModule, MatTableModule,FirstLetterPipe],
  template: `
    <table mat-table [dataSource]="positionsTable" class="mat-elevation-z8">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef><span class="d-none d-sm-block">EQUIPO</span></th>
        <td mat-cell *matCellDef="let element" class="d-flex align-content-stretch " style="min-height:52px">
        @if (element.S_Logo) {
          <img [src]="element.S_Logo" [alt]="element.S_Equipo" class="align-self-center">
        } @else {
          <div class="bg-logo align-self-center" [style.background-color]="'#' + element.S_Color">
            <p>{{ element.S_Equipo | appFirstLetter }}</p>
          </div>
        } 
        <span class="p-10 align-self-center d-none d-sm-block" style="margin-left:5px;">{{ element.S_Equipo }}</span></td>
      </ng-container>
      <ng-container matColumnDef="gamesPlayed">
        <th mat-header-cell *matHeaderCellDef>J</th>
        <td mat-cell *matCellDef="let element" class="score">{{ element.JJ }}</td>
      </ng-container>
      <ng-container matColumnDef="gamesWon">
        <th mat-header-cell *matHeaderCellDef>G</th>
        <td mat-cell *matCellDef="let element" class="score">{{ element.PG }}</td>
      </ng-container>
      <ng-container matColumnDef="gamesLost">
        <th mat-header-cell *matHeaderCellDef>P</th>
        <td mat-cell *matCellDef="let element" class="score">
          {{ element.JJ - element.PG }}
        </td>
      </ng-container>
      <ng-container matColumnDef="goalsFor">
        <th mat-header-cell *matHeaderCellDef>PF</th>
        <td mat-cell *matCellDef="let element" class="score">{{ element.GF }}</td>
      </ng-container>
      <ng-container matColumnDef="goalsAgainst">
        <th mat-header-cell *matHeaderCellDef>PC</th>
        <td mat-cell *matCellDef="let element" class="score">{{ element.GC }}</td>
      </ng-container>
      <ng-container matColumnDef="goalDifference">
        <th mat-header-cell *matHeaderCellDef>AVG</th>
        <td mat-cell *matCellDef="let element" class="score">
          @if (element.GC === 0) {
            {{ element.GF| number : '1.4-4':'es-MX' }}
          }
          @if (element.GC > 0) {
            {{ element.GF / element.GC| number : '1.4-4':'es-MX' }}
          }
          
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  `,
  styleUrl: './positions.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PositionsComponent {
  @Input() public positionsTable!: PositionsTableTeam[];

  public displayedColumns: string[] = [
    'name',
    'gamesPlayed',
    'gamesWon',
    'gamesLost',
    'goalsFor',
    'goalsAgainst',
    'goalDifference',
  ];
}
